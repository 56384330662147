import React from "react";
import Layout from "../../components/layout";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { FiClock } from "react-icons/fi";

import * as styles from "../../styles/blog.module.css";

export default function BlogPage({ data }) {
  return (
    <Layout pageTitle="Blog">
      <div className={`${styles.header} pageHeaderBar`}>Tutorials</div>

      <div className={styles.blogContainer}>
        {data.allMdx.nodes.map((node) => (
          <div className={styles.blogItem}>
            <Link to={`/blog/${node.slug}`} key={node.id}>
              <div className={styles.blogHeader}>
                <GatsbyImage
                  image={getImage(node.frontmatter.hero_image)}
                  alt=""
                />
              </div>
              <div className={styles.blogFooter}>
                <div className={styles.blogMeta}>
                  <div className={styles.timeToRead}>
                    <FiClock />
                    <div>{node.timeToRead} MINUTE READ</div>
                  </div>
                  <div className={styles.dateEdited}>
                    {node.parent.modifiedTime}
                  </div>
                </div>
                <div className={styles.blogTitle}>{node.frontmatter.title}</div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { category: { eq: "tutorial" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          hero_image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        id
        slug
        timeToRead
        parent {
          ... on File {
            id
            name
            modifiedTime(formatString: "MMMM D, YYYY")
          }
        }
      }
    }
  }
`;
